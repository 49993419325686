.login {
  margin: 0;
  padding: 0;
  background-image: url("~@/assets/images/login-background.svg");
  background-size: cover;
  width: 100%;
  height: 100vh;
  position: relative;
  &-pannel {
    position: absolute;
    top: 3vh;
    left: 2vw;
    width: 96vw;
    height: 94vh;
    background-color: rgba(255,255,255,.8);
    backdrop-filter: blur(5px);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    &-form {
      width: 420px;
      background-color: #fff;
      padding: 40px 40px 40px 40px;
      border-radius: 10px;
      box-shadow: 2px 3px 7px  rgba(0,0,0,.2);
    }
    &-right {
      background-image: url("~@/assets/images/login-right.svg");
      background-size: cover;
      width: 40%;
      height: 60%;
      float: right !important;
    }
  }
}