.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-text1-1, #ccc);
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
}

.logo:visited,
.logo:link {
  color: var(--color-text1-1, #ccc);
}

.logo img {
  height: 24px;
  margin-right: 10px;
}

#microWebContainer {
  min-height: calc(100vh - 148px);
  // overflow: auto;
}

.next-shell-content.shell-content-hide-menu {
  padding: 0 !important;
}