.footer {
  line-height: 20px;
  text-align: center;
  margin-bottom: 0;
}

.logo {
  font-weight: bold;
  font-size: 16px;
}

.copyright {
  font-size: 12px;
}