@import '@alifd/theme-design-pro/variables.css';
@import '@alifd/theme-design-pro/dist/next.var.min.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-x: none
}

/**
  * 目前多个子应用之间的样式冲突问题
  * 这里同意重置下全局样式
  */
.next-nav.next-ver .next-menu-sub-menu .next-menu-item-inner {
  height: 40px !important;
  line-height: 42px !important;
  font-size: 12px !important;
} 

.next-nav.next-normal .next-nav-item.next-menu-item.next-selected {
  background: #f2f3f7 !important;
  color: #5584ff !important;
}

.next-nav.next-ver .next-menu-item-inner {
  font-size: 12px !important;
}

.next-shell-brand .next-shell-aside.next-shell-navigation {
  width: 168px !important;
}

.next-shell-brand .next-shell-aside.next-shell-navigation.next-shell-collapse.next-shell-mini {
  width: 60px !important;
}

.next-shell-brand .next-shell-aside.next-shell-navigation.next-shell-collapse.next-shell-mini .next-menu-item-text a,
.next-shell-brand .next-shell-aside.next-shell-navigation.next-shell-collapse.next-shell-mini .next-menu-item-text span {
  opacity: 0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
