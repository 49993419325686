.login-block {
  width: 480px;
  background-color: #fff;
  padding: 40px 40px 40px 40px;
  border-radius: 10px;
  box-shadow: 2px 3px 7px  rgba(0,0,0,.2);
}

.logo-title {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  img {
    width: 90px;
    height: 90px;
  }
  p {
    font-size: 40px;
    padding-left: 20px;
    margin: 0;
    font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","\5fae\8f6f\96c5\9ed1",Arial,Meiryo,"Hiragino Kaku Gothic ProN","MS PGothic",sans-serif;
  }
}

.captcha {
  width: 33%;
  height: 38px;
  float: right !important;
  background: #ccc;
  img {
    cursor: pointer;
    vertical-align: middle;
    width: 100%;
    height: 100%;
  }
}